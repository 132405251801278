<template>
  <div>
    <div>
      <div class="relative py-8 bg-fixed bg-center bg-no-repeat bg-cover min-height:100vh"
        style="background-image: url(/img/bg-hero.webp)">
        <div
          class="absolute inset-0 z-20 bg-center bg-no-repeat bg-cover bg-gradient-to-r from-hero-gradient-from to-hero-gradient-to">
        </div>

        <div class="container relative z-30 pt-20 pb-12 sm:pt-56 sm:pb-48 lg:pt-64 lg:pb-48">
          <div class="flex flex-col items-center justify-center lg:flex-row">
            <div class="border-8 rounded-full shadow-xl border-primary">
              <nuxt-img format="webp" quality="50" src="/img/blog-author.jpg" class="h-48 rounded-full sm:h-56"
                alt="author" />
            </div>
            <div class="pt-8 sm:pt-10 lg:pl-8 lg:pt-0">
              <h1 class="text-4xl text-center text-white font-header sm:text-left sm:text-5xl md:text-6xl">
                Welcome to
              </h1>
              <h1 class="text-4xl text-center text-white font-header sm:text-left sm:text-5xl md:text-6xl">
                Strong Oak Studios
              </h1>
              <div class="flex flex-col justify-center pt-3 sm:flex-row sm:pt-5 lg:justify-start">
                <div class="flex items-center justify-center pl-0 sm:justify-start md:pl-1">
                  <p class="text-lg text-white uppercase font-body">Let's connect</p>
                  <div class="hidden sm:block">
                    <i class="text-3xl bx bx-chevron-right text-primary"></i>
                  </div>
                </div>
                <div class="flex items-center justify-center pt-5 pl-2 sm:justify-start sm:pt-0">
                  <a href="https://twitter.com/strongoakstudiosatx" class="pl-4" target="_blank"
                    rel="noopener noreferrer">
                    <i class="text-2xl text-white bx bxl-twitter hover:text-primary"></i>
                  </a>
                  <a href="https://instagram.com/strongoakstudiosatx" class="pl-4" target="_blank"
                    rel="noopener noreferrer">
                    <i class="text-2xl text-white bx bxl-instagram hover:text-primary"></i>
                  </a>
                  <a href="#contact" class="pl-4">
                    <i class="text-2xl text-white bx bx-envelope hover:text-primary"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="bg-grey-50" id="about">
      <div class="container flex flex-col items-center py-16 md:py-20 lg:flex-row">
        <div class="w-full text-center sm:w-3/4 lg:w-3/5 lg:text-left">
          <h2 class="text-4xl font-semibold uppercase font-header text-primary sm:text-5xl lg:text-6xl">
            Who are we?
          </h2>
          <h4 class="pt-6 text-xl font-medium text-black font-header sm:text-2xl lg:text-3xl">
            We are an Audio, Visual, and General Consulting Services vendor, based in Austin, TX and servicing that
            region.
          </h4>
          <p class="pt-6 leading-relaxed font-body text-grey-20">
            We specialize in providing the best possible experience for your event, whether it be a corporate event,
            conference, non-profit fund raiser, or other event.
            From as simple as a TV as a wireless microphone, to elaborate multi-screen, multi-camera streamed events, we
            can make your vision reality.
          </p>
          <p class="pt-6 leading-relaxed font-body text-grey-20">
            Owned by Daniel Hagen and often staffed by his wife, siblings, and friends, we are a family owned and
            operated. We pride ourselves on making complex things happen in a simple way. We are a small business, and we
            love working with other small businesses.
          </p>
          <p class="pt-6 leading-relaxed font-body text-grey-20">
            Contact us today for a quick quote, or to discuss your event. Even if you don't work with us, I will likely be
            able
            to point you in the right direction.
          </p>
        </div>
      </div>
    </div>

    <div class="container py-16 md:py-20" id="contact">
      <h2 class="text-4xl font-semibold text-center uppercase font-header text-primary sm:text-5xl lg:text-6xl">
        Contact Us
      </h2>
      <h4 class="pt-6 text-xl font-medium text-center text-black font-header sm:text-2xl lg:text-3xl">
        Have Any Questions?
      </h4>
      <div class="w-full pt-5 mx-auto text-center sm:w-2/3 lg:pt-6">
        <p class="font-body text-grey-10">
          If you need a quote, want to rent our equipment, or just want to say hi, we'd love to hear from you.
        </p>
      </div>
      <form @submit.prevent="submitEmail" class="w-full py-10 mx-auto sm:w-3/4">
        <div class="flex flex-col md:flex-row">
          <input class="w-full px-4 py-3 mr-3 text-black rounded border-grey-50 font-body md:w-1/2 lg:mr-5"
            v-model="contactName" placeholder="Name" type="text" id="name" />
          <input
            class="w-full px-4 py-3 mt-6 text-black rounded border-grey-50 font-body md:mt-0 md:ml-3 md:w-1/2 lg:ml-5"
            v-model="contactEmail" placeholder="Email" type="text" id="email" />
        </div>
        <textarea class="w-full px-4 py-3 mt-6 text-black rounded border-grey-50 font-body md:mt-8" placeholder="Message"
          v-model="contactMessage" id=" message" cols="30" rows="10"></textarea>
        <div ref="FriendlyChallengeContainer" class="frc-captcha"
          :data-sitekey="runtimeConfig.friendlyChallengeSiteKey" />
        <button type="submit" :disabled="!contactSendEnabled"
          class="flex items-center justify-center px-8 py-3 mt-6 text-lg font-bold uppercase rounded font-header"
          :class="contactSendEnabled ? 'text-white bg-primary hover:bg-grey-20' : 'text-grey-10 bg-grey-50 hover:bg-grey-50'">
          Send
          <i class="relative text-3xl bx bx-chevron-right -right-2"></i>
        </button>
      </form>

      <div class="bg-fixed bg-center bg-no-repeat bg-cover lg:bg-[center_bottom_-25rem] h-72 sm:h-64 md:h-72 lg:h-96"
        style="background-image: url(/img/map.webp)">
      </div>

    </div>
  </div>
</template>

<script setup lang="ts">
import { WidgetInstance as FriendlyChallengeWidgetInstance } from "friendly-challenge";
import { ref, onMounted, onUnmounted } from "vue";

const runtimeConfig = useRuntimeConfig()

const FriendlyChallengeContainer = ref();
const FriendlyChallengeWidget = ref();

const contactEmail = ref('');
const contactName = ref('');
const contactMessage = ref('');
const contactEmailError = ref('');
const contactChallengeToken = ref('');
const contactSendEnabled = ref(false);
const data = ref('')

const FriendlyChallengeDoneCallback = ((solution: string) => {
  contactChallengeToken.value = solution
  contactSendEnabled.value = true
  // console.log('FriendlyChallengeDoneCallback', contactChallengeToken.value)
})

const FriendlyChallengeErrorCallback = ((error: any) => {
  contactSendEnabled.value = false
  // console.log('FriendlyChallengeErrorCallback', error)
})

onMounted(() => {
  if (FriendlyChallengeContainer.value) {
    FriendlyChallengeWidget.value = new FriendlyChallengeWidgetInstance(FriendlyChallengeContainer.value, {
      startMode: "auto",
      sitekey: runtimeConfig.friendlyChallengeSiteKey,
      doneCallback: FriendlyChallengeDoneCallback,
      errorCallback: FriendlyChallengeErrorCallback,
    });
  }
});

onUnmounted(() => {
  if (FriendlyChallengeWidget.value) {
    FriendlyChallengeWidget.value.destroy();
  }
})

async function submitEmail() {
  // console.log('Start Submit')
  contactSendEnabled.value = false

  const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i

  if (contactEmail.value && !regex.test(contactEmail.value)) {
    contactEmailError.value = "Invalid email address"
    // console.log("Invalid email address")
    return
  } else {
    contactEmailError.value = ""
  }

  const contactBody = JSON.stringify({
    email: contactEmail.value,
    name: contactName.value,
    message: contactMessage.value,
    contactChallengeToken: contactChallengeToken.value
  })
  const returned = useFetch('/contact/', {
    method: 'POST',
    body: contactBody
  })
  contactEmail.value = ''
  contactName.value = ''
  contactMessage.value = ''
  contactSendEnabled.value = true
  // console.log('Returned Data', returned.data)

  // const { error } = await supabase
  //   .from('contactForm')
  //   .insert([
  //     { email: contactEmail.value, name: contactName.value, message: contactMessage.value },
  //   ]).then(() => {
  //     contactEmail.value = ''
  //     contactName.value = ''
  //     contactMessage.value = ''
  //   });

  // if (error) {
  //   console.error("Error submitting email:", error.message);
  //   return;
  // }

  // console.log("Email submitted successfully!");
}
</script>
